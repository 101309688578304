<template>
  <div>
    <ConfirmModal
      v-model="isDeleteModalActive"
      :items="deleteItem"
      title="Confirm to Delete Customer"
      @confirm="confirmDelete($event)" />
    <StatusModal
      v-model="isConfirmDeleteModalActive"
      status-title="Delete Customer Profile"
      status-description="Delete Customer Profile Succeed"
      status-color="danger"
      status-icon="Trash2Icon"
      @discard="onDiscard()" />
    <CustomerAddContact
      ref="customerContactAdd"
      :is-sidebar-active.sync="isSidebarActive"
      @add-customer-contact="addCustomerContact($event)"
    />
    <CustomerDetailContact
      :is-sidebar-detail-active.sync="isSidebarDetailActive"
      :initial-customer-contact="initialCustomerContact"
      :selected-item.sync="selectedItem"
      @update-company-contact="updateCompanyContact($event)"
      @delete-item="openDeleteModal($event)" />
    <b-card title="Customer Contact">
      <TableTop
        v-model="pagination.limit"
        label="Add Customer Contact"
        @input="fetchFilter()"
        @active-sidebar="activeSidebar()" />
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        @row-clicked="rowClick($event)">
        <template #cell(contactPhoneNo)="{item}">
          <span>
            {{ item.contactPhoneNo }}
            <b-button
              v-if="item.contactPhoneNo"
              variant="outline-primary"
              @click="openClickToCallModal(item.contactPhoneNo)">
              <feather-icon
                variant="primary"
                icon="PhoneIcon"
                size="15"
                class="cursor-pointer"
              />
            </b-button>
          </span>
        </template>
        <template #cell(contactPhoneNo2)="{item}">
          <span>
            {{ item.contactPhoneNo2 }}
            <b-button
              v-if="item.contactPhoneNo2"
              variant="outline-primary"

              @click="openClickToCallModal(item.contactPhoneNo2)">

              <feather-icon
                icon="PhoneIcon"
                size="15"
                class="cursor-pointer"
              />
            </b-button>
          </span>
        </template>
      </b-table>
    </b-card>
    <b-col
      cols="12"
      sm="12"
      class="d-flex align-items-center justify-content-center justify-content-sm-end">
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.totalDocs"
        :per-page="pagination.limit"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @change="showCurrentPage($event)"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18" />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18" />
        </template>
      </b-pagination>
    </b-col>
    <ClickToCallModal
      v-model="isModal"
      :phone-number="phoneNumberModal" />
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TableTop from '@/components/TableTop.vue'
import ConfirmModal from '@/components/Modal/ConfirmModal.vue'
import StatusModal from '@/components/Modal/StatusModal.vue'
import CustomerContactProvider from '@/resources/CustomerContactProvider'
import ClickToCallModal from '@/components/Modal/ClickToCallModal.vue'
import CustomerAddContact from './CustomerAddContact.vue'
import CustomerDetailContact from './CustomerDetailContact.vue'

const CustomerContactService = new CustomerContactProvider()

export default {
  components: {
    CustomerAddContact,
    CustomerDetailContact,
    TableTop,
    ConfirmModal,
    StatusModal,
    ClickToCallModal
  },
  data () {
    return {
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      isConfirmDeleteModalActive: false,
      perPage: 10,
      currentPage: 1,
      totalUsers: 100,
      fields: [
        // { key: 'code', label: 'Code' },
        { key: 'firstName', label: 'First Name' },
        { key: 'lastName', label: 'Last Name' },
        { key: 'contactPhoneNo', label: 'Contact Phone Number' },
        { key: 'contactPhoneNo2', label: 'Contact Phone Number 2' },
        { key: 'contactEmail', label: 'Contact Email' },
        { key: 'contactLine', label: 'Contact Line' },
        { key: 'contactFacebook', label: 'Contact Facebook' },
        { key: 'detail', label: 'Detail' },
        { key: 'note1', label: 'Note1' },
        { key: 'note2', label: 'Note2' },
        { key: 'note3', label: 'Note3' },
        { key: 'note4', label: 'Note4' },
        { key: 'note5', label: 'Note5' }
      ],
      customer: {
        customerId: '#1234567',
        customerName: 'Lorem ipsum',
        customerType: 'Lorem ipsum',
        customerGroup: 'Lorem ipsum',
        sapSold: 'Lorem ipsum',
        sapShip: 'Lorem ipsum',
        refCode1: 'Lorem ipsum',
        refCode2: 'Lorem ipsum',
        refCode3: 'Lorem ipsum',
        companyAddress: 'Lorem ipsum',
        companySubDistrict: 'Lorem ipsum',
        companyDistrict: 'Lorem ipsum',
        companyProvince: 'Lorem ipsum',
        companyZipCode: 'Lorem ipsum',
        companyOfficePhone: 'Lorem ipsum',
        companyMobilePhone: 'Lorem ipsum',
        companyEmail: 'Lorem ipsum',
        saleGroup: 'Lorem ipsum',
        group: 'Lorem ipsum',
        centralCompany: 'Lorem ipsum',
        remark: ['Lorem ipsum', 'Lorem ipsum', 'Lorem ipsum']
      },
      items: [
        {
          id: '#1234567',
          firstName: 'Lorem ipsum',
          lastName: 'Lorem ipsum',
          contactPhoneNo: 'Lorem ipsum',
          contactEmail: 'Lorem ipsum',
          contactLine: 'Lorem ipsum',
          detail: 'Lorem ipsum',
          note1: 'Lorem ipsum',
          note2: 'Lorem ipsum',
          note3: 'Lorem ipsum',
          note4: 'Lorem ipsum',
          note5: 'Lorem ipsum'
        },
        {
          id: '#1234567',
          firstName: 'Lorem ipsum',
          lastName: 'Lorem ipsum',
          contactPhoneNo: 'Lorem ipsum',
          contactEmail: 'Lorem ipsum',
          contactLine: 'Lorem ipsum',
          detail: 'Lorem ipsum',
          note1: 'Lorem ipsum',
          note2: 'Lorem ipsum',
          note3: 'Lorem ipsum',
          note4: 'Lorem ipsum',
          note5: 'Lorem ipsum'
        }
      ],
      deleteItem: {},
      initialCustomerContact: {},
      selectedItem: {},
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      isModal: false,
      phoneNumberModal: ''
    }
  },
  computed: {
    customerId () {
      return this.$route.params.id
    }
  },
  mounted () {
    this.getCustomerContact()
  },
  methods: {
    openClickToCallModal (phoneNumber) {
      this.isModal = !this.isModal
      this.phoneNumberModal = phoneNumber
    },
    async getCustomerContact () {
      try {
        const data = await CustomerContactService.findByCustomerId(this.pagination.page, this.pagination.limit, this.customerId)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async addCustomerContact (val) {
      try {
        const payload = { ...val }
        payload.customerId = this.customerId
        payload.contactPhoneNo = this.$replacePhoneNumber(payload.contactPhoneNo, '+66')
        payload.contactPhoneNo2 = this.$replacePhoneNumber(payload.contactPhoneNo2, '+66')
        await CustomerContactService.createGroup(payload)
        await this.getCustomerContact()
        this.resetFormAndToggleSideBar(this.$refs.customerContactAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Customer Group has been added'
          }
        })
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Customer Group has been Failed'
          }
        })
      }
    },
    async updateCompanyContact (val) {
      try {
        const payload = { ...val }
        delete payload.id
        payload.contactPhoneNo = this.$replacePhoneNumber(payload.contactPhoneNo, '+66')
        payload.contactPhoneNo2 = this.$replacePhoneNumber(payload.contactPhoneNo2, '+66')
        const { data } = await CustomerContactService.updateGroup(val.id, payload)
        await this.getCustomerContact()
        this.setDataInitialAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Customer contact has been updated'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Update Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Customer contact has been Failed'
          }
        })
      }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await CustomerContactService.deleteGroup(val.ID)
        await this.getCustomerContact()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Customer Contact has been deleted'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Customer Contact  has been Failed ${error}`
          }
        })
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    rowClick (val) {
      this.setDataInitialAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    onDiscard () {
      this.$router.push('/customer')
    },
    openDeleteModal (val) {
      this.deleteItem = {
        ID: val.id
      }
      this.isDeleteModalActive = true
    },
    setDataInitialAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialCustomerContact = { ...val }
    },
    fetchFilter () {
      this.setPagination(1)
      this.getCustomerContact()
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getCustomerContact()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
